import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../../components/core/dashboard/Button';
import { icons } from '../../../../../constants';
import PlainContainer from '../../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../../components/core/containers/SplitContainer';
import Card from '../../../../../components/core/dashboard/Card';
import DetailsRow from '../../../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../../../components/core/containers/DetailsItem';
import DetailsItemWithPreview from '../../../../../components/core/containers/DetailsItemWithPreview';
import Table from '../../../../../components/core/table/containers/Table';
import TableHeading from '../../../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../../../components/core/table/containers/TableRows';
import TableRow from '../../../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../../../utils';
import { ProductServices } from '../../../../../services';
import PopUp from '../../../../../components/core/popup';
import NoDataContainer from '../../../../../components/core/containers/NoDataContainer';
import DetailsItemInnerText from '../../../../../components/core/containers/DetailsItemInnerText';
import AddProductHighlightPopUp from '../../../../../components/dashboard/popups/Product/AddProductHighlightPopUp';
import UpdateProductHighlightPopUp from '../../../../../components/dashboard/popups/Product/UpdateProductHighlightPopUp';
import AddProductActionPopUp from '../../../../../components/dashboard/popups/Product/AddProductActionPopUp';
import UpdateProductActionPopUp from '../../../../../components/dashboard/popups/Product/UpdateProductActionPopUp';
import AddProductSubProductsPopUp from '../../../../../components/dashboard/popups/Product/AddProductSubProductsPopup';
import UpdateProductSubProductsPopUp from '../../../../../components/dashboard/popups/Product/UpdateProductsSubProductsPopup';
import UpdateUniformsProductPageSitemapPopUp from '../../../../../components/dashboard/popups/Pages/UniformsPage/UpdateUniformsProductPageSitemapPopup';

const ProductDetailsPage = ({ user }) => {
    const navigate = useNavigate();
    const params = useParams();

    const [product, setProduct] = useState(null);

    const handleGetProductRequest = async () => {
        const getProductReq = await ProductServices.getThisAuthProduct({
            product_id: params.id,
            token: user.access_token
        });

        if(getProductReq.status === false){
            ToastAlert.notifyError(getProductReq.message);
            setProduct(null);
        }
        else{
            console.log("sub products",getProductReq.data);
            setProduct(getProductReq.data);
        }
    }

    useEffect(() => {
        handleGetProductRequest();
    }, []);

    const [showUpdateSitemapPopUp, setShowUpdateSitemapPopUp] = useState(false);
    const [uniformProductToUpdate, setUniformProductToToUpdate] = useState(null);

    const handleShowUpdateAboutSitemapPopUp = async (data) => {
        console.log(data);
        setUniformProductToToUpdate(data);
        setShowUpdateSitemapPopUp(true);
    }

    const handleConcludeUpdateSitemapPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSitemapPopUp(false);
        }
        else{
            setShowUpdateSitemapPopUp(false);
            handleGetProductRequest();
        }
    }

    const [showAddHighlightPopUp, setShowAddHighlightPopUp] = useState(false);

    const handleConcludeAddHighlightPopUp = async (e) => {
        if(e.status === false){
            setShowAddHighlightPopUp(false);
        }
        else{
            setShowAddHighlightPopUp(false);
            handleGetProductRequest();
        }
    }

    const [showUpdateHighlightPopUp, setShowUpdateHighlightPopUp] = useState(false);
    const [highlightToUpdate, setHighlightToUpdate] = useState();

    const handleShowUpdateHighlightPopUp = (highlight_data) => {
        setHighlightToUpdate(highlight_data);
        setShowUpdateHighlightPopUp(true);
    }

    const handleConcludeUpdateHighlightPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateHighlightPopUp(false);
        }
        else{
            setShowUpdateHighlightPopUp(false);
            handleGetProductRequest();
        }
    }

    const handleSaveSortedHighlightList = async (list) => {

        const sortHighlghtsReq = await ProductServices.sortHighlights({
            items: JSON.stringify(list),
            product_id: product.id,
            token: user.access_token
        });

        if(sortHighlghtsReq.status === false){
            ToastAlert.notifyError(sortHighlghtsReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortHighlghtsReq.message);
            handleGetProductRequest();
        }
    }

    const handleSortHighlightEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(product.product.highlights, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedHighlightList(entityIdWithIndex);

        let data = {
            id: product.id,
            product_id: product.product_id,
            seo: product.seo,
            sitemap: product.sitemap,
            product: {
                name: product.product.name,
                title: product.product.title,
                short_description: product.product.short_description,
                description: product.product.description,
                image: product.product.image,
                highlights: [...arrayMove],
                actions: product.product.actions,
                sub_products: product.product.sub_products,
            },
            created_by: product.created_by,
            is_active: product.is_active,
            is_archived: product.is_archived,
            createdAt: product.createdAt,
            updatedAt: product.updatedAt

        }

        setProduct(data);
    }

    const [showAddActionPopUp, setShowAddActionPopUp] = useState(false);

    const handleConcludeAddActionPopUp = async (e) => {
        if(e.status === false){
            setShowAddActionPopUp(false);
        }
        else{
            setShowAddActionPopUp(false);
            handleGetProductRequest();
        }
    }

    const [showUpdateActionPopUp, setShowUpdateActionPopUp] = useState(false);
    const [actionToUpdate, setActionToUpdate] = useState();

    const handleShowUpdateActionPopUp = (action_data) => {
        setActionToUpdate(action_data);
        setShowUpdateActionPopUp(true);
    }

    const handleConcludeUpdateActionPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateActionPopUp(false);
        }
        else{
            setShowUpdateActionPopUp(false);
            handleGetProductRequest();
        }
    }

    const [showAddSubProductsPopUp, setShowAddSubProductsPopUp] = useState(false);

    const handleConcludeAddSubProductsPopUp = async (e) => {
        if(e.status === false){
            setShowAddSubProductsPopUp(false);
        }
        else{
            setShowAddSubProductsPopUp(false);
            handleGetProductRequest();
        }
    }

    const [showUpdateSubProductsPopUp, setShowUpdateSubProductsPopUp] = useState(false);
    const [subProductsToUpdate, setSubProductsToUpdate] = useState();

    const handleShowUpdateSubProductsPopUp = (action_data) => {
        setSubProductsToUpdate(action_data);
        setShowUpdateSubProductsPopUp(true);
    }

    const handleConcludeUpdateSubProductsPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSubProductsPopUp(false);
        }
        else{
            setShowUpdateSubProductsPopUp(false);
            handleGetProductRequest();
        }
    }

    const handleSaveSortedActionList = async (list) => {

        const sortActionsReq = await ProductServices.sortActions({
            items: JSON.stringify(list),
            product_id: product.id,
            token: user.access_token
        });

        if(sortActionsReq.status === false){
            ToastAlert.notifyError(sortActionsReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortActionsReq.message);
            handleGetProductRequest();
        }
    }

    const handleSortActionEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(product.product.actions, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedActionList(entityIdWithIndex);

        let data = {
            id: product.id,
            product_id: product.product_id,
            seo: product.seo,
            sitemap: product.sitemap,
            product: {
                name: product.product.name,
                title: product.product.title,
                short_description: product.product.short_description,
                description: product.product.description,
                image: product.product.image,
                highlights: product.product.highlights,
                actions: [...arrayMove],
                sub_products: product.product.sub_products,
            },
            created_by: product.created_by,
            is_active: product.is_active,
            is_archived: product.is_archived,
            createdAt: product.createdAt,
            updatedAt: product.updatedAt

        }

        setProduct(data);
    }

    const handleDeleteProductHighlight = async (action_id) => {
        console.log(action_id);
        const deleteProductHighlightReq = await ProductServices.deleteProductHighlight({
            product_id : params.id,
            id:action_id,
            token: user.access_token
        });

        if(deleteProductHighlightReq.status === false){
            ToastAlert.notifyError(deleteProductHighlightReq.message);
            setProduct([]);
        }
        else{
            handleGetProductRequest()
        }
    }

    const handleDeleteProductActions = async (action_id) => {
        console.log(action_id);
        const deleteProductActionsReq = await ProductServices.deleteProductActions({
            product_id :params.id,
            id:action_id,
            token: user.access_token
        });

        if(deleteProductActionsReq.status === false){
            ToastAlert.notifyError(deleteProductActionsReq.message);
            setProduct([]);
        }
        else{
            handleGetProductRequest()
        }
    }

    const handleDeleteSubProductsActions = async (action_id) => {
        console.log(action_id);
        const deleteSubProductsReq = await ProductServices.deleteSubProducts({
            product_id : params.id,
            id:action_id,
            token: user.access_token
        });

        if(deleteSubProductsReq.status === false){
            ToastAlert.notifyError(deleteSubProductsReq.message);
            setProduct([]);
        }
        else{
            handleGetProductRequest()
        }
    }

    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

    const SortableHighlightItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={5} isCenter={false} hasPreview={false} lable={value?.description} />
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleShowUpdateHighlightPopUp(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteProductHighlight(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
    
    const SortableHighlightContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });

    const SortableActionItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={5} isCenter={false} hasPreview={false} lable={value?.name} />
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleShowUpdateActionPopUp(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteProductActions(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
    
    const SortableActionContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });
 
    const handleSaveSortedSubProductsList = async (list) => {

        const sortActionsReq = await ProductServices.sortSubProducts({
            items: JSON.stringify(list),
            product_id: product.id,
            token: user.access_token
        });

        if(sortActionsReq.status === false){
            ToastAlert.notifyError(sortActionsReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortActionsReq.message);
            handleGetProductRequest();
        }
    }

    const handleSortSubProductsEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(product.product.sub_products, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedSubProductsList(entityIdWithIndex);

        let data = {
            id: product.id,
            product_id: product.product_id,
            seo: product.seo,
            sitemap: product.sitemap,
            product: {
                name: product.product.name,
                title: product.product.title,
                short_description: product.product.short_description,
                description: product.product.description,
                image: product.product.image,
                highlights: product.product.highlights,
                actions:product.product.actions,
                sub_products: [...arrayMove],
            },
            created_by: product.created_by,
            is_active: product.is_active,
            is_archived: product.is_archived,
            createdAt: product.createdAt,
            updatedAt: product.updatedAt

        }

        setProduct(data);
    }
    
    const SortableSubProductsItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={5} isCenter={false} hasPreview={false} lable={value?.name} />
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleShowUpdateSubProductsPopUp(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() =>handleDeleteSubProductsActions(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
    
    const SortableSubProductsContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Product Details'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to the uniform in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"SEO"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate(`seo`)} isBottomAction={false}>
                    {
                        product?.seo === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No SEO Details found!"}
                                    description={"Update seo details by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => console.log("seo")}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetProductRequest("refresh")}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            {
                                product?.seo?.title != undefined && product?.seo?.title != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"title"} 
                                        description={defaults.capitalize(product?.seo?.title)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                product?.seo?.slug != undefined && product?.seo?.slug != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"slug"} 
                                        description={defaults.capitalize(product?.seo?.slug)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                product?.seo?.description != undefined && product?.seo?.description != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"description"} 
                                        description={defaults.capitalize(product?.seo?.description)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                product?.seo?.keywords != undefined && product?.seo?.keywords != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"keywords"} 
                                        description={defaults.capitalize(product?.seo?.keywords)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                product?.seo?.canonical_url != undefined && product?.seo?.canonical_url != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"canonical URL"} 
                                        description={defaults.capitalize(product?.seo?.canonical_url)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                product?.seo?.twitter_handler != undefined && product?.seo?.twitter_handler != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"twitter handler"} 
                                        description={defaults.capitalize(product?.seo?.twitter_handler)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                product?.seo?.image != undefined
                                ?
                                <DetailsRow>
                                    <DetailsItemWithPreview 
                                        lable={`Image`}
                                        preview={`${globals.API_BASE_URL}${product?.seo?.image?.image_url?.full?.high_res}`}
                                        previewType={"image"}
                                        fileName={product?.seo?.image?.name?.original}
                                        fileExtension={product?.seo?.image?.extension?.original}
                                        fileSize={product?.seo?.image?.size?.current}
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                        </PlainContainer>
                    }
                </Card>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Sitemap"} headingActionIcon={icons.addSolidCircle} headingAction={() => handleShowUpdateAboutSitemapPopUp(product)}  isBottomAction={false}>
                        {
                            product?.sitemap === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No Sitemap Details found!"}
                                        description={"Update sitemap details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateSitemapPopUp(true)}
										hasSecondaryAction={true}
										secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetProductRequest("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                {
                                    product?.sitemap?.page?.name != ""
                                    ?
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"page"} 
                                            description={defaults.capitalize(product?.sitemap?.page?.name)} 
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                                {
                                    product?.sitemap?.image != undefined
                                    ?
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Image`}
                                            preview={`${globals.API_BASE_URL}${product?.sitemap?.image?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={product?.sitemap?.image?.name?.original}
                                            fileExtension={product?.sitemap?.image?.extension?.original}
                                            fileSize={product?.sitemap?.image?.size?.current}
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Basic Details"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate(`/page/uniforms/product/edit/${product?.id}`)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"name"} 
                                    description={defaults.capitalize(product?.product?.name)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"title"} 
                                    description={defaults.capitalize(product?.product?.title)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"short description"} 
                                    description={defaults.capitalize(product?.product?.short_description)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItemInnerText 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"description"} 
                                    content={defaults.capitalize(product?.product?.description)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItemWithPreview 
                                    lable={`Image`}
                                    preview={`${globals.API_BASE_URL}${product?.product?.image?.image_url?.full?.high_res}`}
                                    previewType={"image"}
                                    fileName={product?.product?.image?.name?.original}
                                    fileExtension={product?.product?.image?.extension?.original}
                                    fileSize={product?.product?.image?.size?.current}
                                />
                            </DetailsRow>
                        </PlainContainer>
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Highlights"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddHighlightPopUp(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={5} lable={"lable"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableHighlightContainer onSortEnd={handleSortHighlightEnd} useDragHandle>
                                    {product?.product?.highlights.map((item, index) => (
                                        <SortableHighlightItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableHighlightContainer>
                            </Table>
                        </PlainContainer>
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Actions"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddActionPopUp(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={5} lable={"lable"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableActionContainer onSortEnd={handleSortActionEnd} useDragHandle>
                                    {product?.product?.actions.map((item, index) => (
                                        <SortableActionItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableActionContainer>
                            </Table>
                        </PlainContainer>
                    </Card>
                </PlainContainer>
                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Sub Products"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddSubProductsPopUp(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={5} lable={"lable"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableSubProductsContainer onSortEnd={handleSortSubProductsEnd} useDragHandle>
                                    {product?.product?.sub_products.map((item, index) => (
                                        <SortableSubProductsItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableSubProductsContainer>
                            </Table>
                        </PlainContainer>
                    </Card>
                </PlainContainer>
            </PlainContainer>

            {
                showAddHighlightPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Highlight" closePopUp={() => handleConcludeAddHighlightPopUp({status: false})}>
                    <AddProductHighlightPopUp user={user} product_id={product.id} concludeAddHighlightPopUp={(e) => handleConcludeAddHighlightPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateHighlightPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Highlight" closePopUp={() => handleConcludeUpdateHighlightPopUp({status: false})}>
                    <UpdateProductHighlightPopUp user={user} product_id={product.id} highlight={highlightToUpdate} concludeUpdateHighlightPopUp={(e) => handleConcludeUpdateHighlightPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddActionPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Highlight" closePopUp={() => handleConcludeAddActionPopUp({status: false})}>
                    <AddProductActionPopUp user={user} product_id={product.id} concludeAddActionPopUp={(e) => handleConcludeAddActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateActionPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Action" closePopUp={() => handleConcludeUpdateActionPopUp({status: false})}>
                    <UpdateProductActionPopUp user={user} product_id={product.id} action_data={actionToUpdate} concludeUpdateActionPopUp={(e) => handleConcludeUpdateActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }
             {
                showAddSubProductsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Action" closePopUp={() => handleConcludeAddSubProductsPopUp({status: false})}>
                    <AddProductSubProductsPopUp user={user} product_id={product.id} concludeAddSubProductsPopUp={(e) => handleConcludeAddSubProductsPopUp(e)} />
                </PopUp>
                :
                <></>
            }
             {
                showUpdateSubProductsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Action" closePopUp={() => handleConcludeUpdateSubProductsPopUp({status: false})}>
                    <UpdateProductSubProductsPopUp user={user} product_id={product.id} sub_products_data={subProductsToUpdate} concludeUpdateSubProductsPopUp={(e) => handleConcludeUpdateSubProductsPopUp(e)} />
                </PopUp>
                :
                <></>
            }
            {
				showUpdateSitemapPopUp === true 
				? 
				<PopUp width="max" hasBottomPadding={true} heading="Update Sitemap" closePopUp={() => handleConcludeUpdateSitemapPopUp({ status: false })}>
					<UpdateUniformsProductPageSitemapPopUp user={user} sitemap={uniformProductToUpdate} concludeUpdateSitemapPopUp={(e) => handleConcludeUpdateSitemapPopUp(e) } />
				</PopUp>
			:
				<></>
			}
        </>
    )
}

export default ProductDetailsPage