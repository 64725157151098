import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { AboutPageServices } from '../../../services';
import PopUp from '../../../components/core/popup';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import DetailsItemWithPreview from '../../../components/core/containers/DetailsItemWithPreview';
import UpdateAboutUsPageAboutSectionHeadingsPopUp from '../../../components/dashboard/popups/Pages/AboutUsPage/UpdateAboutUsPageAboutSectionHeadingsPopUp';
import UpdateAboutUsPageAboutCoverImagesPopUp from '../../../components/dashboard/popups/Pages/AboutUsPage/UpdateAboutUsPageAboutCoverImagesPopUp';
import UpdateAboutUsPageAboutStatsPopUp from '../../../components/dashboard/popups/Pages/AboutUsPage/UpdateAboutUsPageAboutStatsPopUp';
import AddAboutUsPageAboutHighlightPopUp from '../../../components/dashboard/popups/Pages/AboutUsPage/AddAboutUsPageAboutHighlightPopUp';
import UpdateAboutUspageAboutHighlightPopUp from '../../../components/dashboard/popups/Pages/AboutUsPage/UpdateAboutUsPageAboutHighlightPopUp';
import UpdateAboutUsPageSitemapPopUp from '../../../components/dashboard/popups/Pages/AboutUsPage/UpdateAboutUsPageSitemapPopUp';
import UpdateAboutPageAboutActionPopUp from '../../../components/dashboard/popups/Pages/AboutUsPage/UpdateAboutPageAboutActionPopUp';
import UpdateAboutUsPageDescriptionPopUp from '../../../components/dashboard/popups/Pages/AboutUsPage/UpdateAboutUsPageDescriptionPopUp';

const AboutUsPage = ({ user }) => {
    const navigate = useNavigate();

    const [seo, setSEO] = useState(null);

    const handleGetSEORequest = async () => {
        const getSEOReq = await AboutPageServices.getAuthSEO({
            token: user.access_token
        });

        if(getSEOReq.status === false){
            ToastAlert.notifyError(getSEOReq.message);
            setSEO(null);
        }
        else{
            setSEO(getSEOReq.data);
        }
    }

    useEffect(() => {
        handleGetSEORequest();
    }, []);

    const [sitemap, setSitemap] = useState(null);

    const handleGetSitemapRequest = async () => {
        const getSitemapReq = await AboutPageServices.getAuthSitemap({
            token: user.access_token
        });

        if(getSitemapReq.status === false){
            ToastAlert.notifyError(getSitemapReq.message);
            setSitemap(null);
        }
        else{
            setSitemap(getSitemapReq.data);
        }
    }

    useEffect(() => {
        handleGetSitemapRequest();
    }, []);

    const [showUpdateSitemapPopUp, setShowUpdateSitemapPopUp] = useState(false);

    const handleConcludeUpdateSitemapPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSitemapPopUp(false);
        }
        else{
            setShowUpdateSitemapPopUp(false);
            handleGetSitemapRequest();
        }
    }

    const [aboutSection, setAboutSection] = useState(null);

    const handleGetAboutSectionRequest = async () => {
        const getAboutSectionReq = await AboutPageServices.getAuthAboutSection({
            token: user.access_token
        });

        if(getAboutSectionReq.status === false){
            ToastAlert.notifyError(getAboutSectionReq.message);
            setAboutSection(null);
        }
        else{
            setAboutSection(getAboutSectionReq.data);
        }
    }

    useEffect(() => {
        handleGetAboutSectionRequest();
    }, []);

    const [showUpdateAboutSectionHeadingsPopUp, setShowUpdateAboutSectionHeadingsPopUp] = useState(false);

    const handleConcludeUpdateAboutSectionHeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutSectionHeadingsPopUp(false);
        }
        else{
            setShowUpdateAboutSectionHeadingsPopUp(false);
            handleGetAboutSectionRequest();
        }
    }

    const [showUpdateAboutCoverImagesPopUp, setShowUpdateAboutCoverImagesPopUp] = useState(false);

    const handleConcludeUpdateAboutCoverImagesPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutCoverImagesPopUp(false);
        }
        else{
            setShowUpdateAboutCoverImagesPopUp(false);
            handleGetAboutSectionRequest();
        }
    }

    const [showUpdateAboutStatsPopUp, setShowUpdateAboutStatsPopUp] = useState(false);

    const handleConcludeUpdateAboutStatsPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutStatsPopUp(false);
        }
        else{
            setShowUpdateAboutStatsPopUp(false);
            handleGetAboutSectionRequest();
        }
    }

    const[ showAddAboutHighlightPopUp , setShowAddAboutHighlightPopUp]=useState(false);

    const handleConcludeAddAboutHighlightPopUp= async (e)=>{
        if(e.status === false){
            setShowAddAboutHighlightPopUp(false)
        }
        else{
            setShowAddAboutHighlightPopUp(false)
            handleGetAboutSectionRequest();
        }
    }

    const [showUpdateAboutDescriptionPopUp, setShowUpdateAboutDescriptionPopUp] = useState(false);

    const handleConcludeUpdateAboutDescriptionPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutDescriptionPopUp(false);
        }
        else{
            setShowUpdateAboutDescriptionPopUp(false);
            handleGetAboutSectionRequest();
        }
    }

    const [showUpdateAboutHighlightPopUp, setShowUpdateAboutHighlightPopUp] = useState(false);
    const [aboutHighlightToUpdate, setAboutHighlightToUpdate] = useState(null);

    const handleShowUpdateAboutHighlightPopUp = async (data) => {
        console.log(data);
        setAboutHighlightToUpdate(data);
        setShowUpdateAboutHighlightPopUp(true);
    }

    const handleConcludeUpdateAboutHighlightPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutHighlightPopUp(false);
        }
        else{
            setShowUpdateAboutHighlightPopUp(false);
            handleGetAboutSectionRequest();
        }
    }

    const handleDeleteAboutHighlight = async (action_id) => {
        console.log(action_id);
        const deleteAboutHighlightReq = await AboutPageServices.deleteAboutHighlight({
            id:action_id,
            token: user.access_token
        });

        if(deleteAboutHighlightReq.status === false){
            ToastAlert.notifyError(deleteAboutHighlightReq.message);
            setAboutSection([]);
        }
        else{
            handleGetAboutSectionRequest()
        }
    }


    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

    const SortableAboutHighlightItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={4} isCenter={false} hasPreview={true} previewType={'image'} previewItem={`${globals.API_BASE_URL}${value.icon.image_url.thumbnail.low_res}`} lable={value.lable} />
            <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleShowUpdateAboutHighlightPopUp(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteAboutHighlight(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
    
    const SortableAboutHighlightContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });


    const handleSaveAboutHighlightSortedList = async (list) => {

        const sortHighlghtsReq = await AboutPageServices.sortAboutHighlights({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortHighlghtsReq.status === false){
            ToastAlert.notifyError(sortHighlghtsReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortHighlghtsReq.message);
            handleGetAboutSectionRequest();
        }
    }

    const handleAboutHighlightSortEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(aboutSection.highlights, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveAboutHighlightSortedList(entityIdWithIndex);

        let data = {
            section_headings: aboutSection.section_headings,
            cover_images: aboutSection.cover_images,
            statistics: aboutSection.statistics,
            highlights: [...arrayMove]
        }

        setAboutSection(data);
    }

    const [showUpdateAboutActionPopUp, setShowUpdateAboutActionPopUp] = useState(false);

    const handleConcludeUpdateAboutActionPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutActionPopUp(false);
        }
        else{
            setShowUpdateAboutActionPopUp(false);
            handleGetAboutSectionRequest();
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='About Us Page'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to About Us page in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"SEO"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate("seo")} isBottomAction={false}>
                    {
                        seo === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No SEO Details found!"}
                                    description={"Update seo details by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => navigate("seo")}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetSEORequest("refresh")}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            {
                                seo?.title != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"title"} 
                                        description={defaults.capitalize(seo?.title)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.description != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"description"} 
                                        description={defaults.capitalize(seo?.description)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.keywords != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"keywords"} 
                                        description={defaults.capitalize(seo?.keywords)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.canonical_url != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"canonical URL"} 
                                        description={defaults.capitalize(seo?.canonical_url)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.twitter_handler != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"twitter handler"} 
                                        description={defaults.capitalize(seo?.twitter_handler)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.image != undefined
                                ?
                                <DetailsRow>
                                    <DetailsItemWithPreview 
                                        lable={`Image`}
                                        preview={`${globals.API_BASE_URL}${seo?.image?.image_url?.full?.high_res}`}
                                        previewType={"image"}
                                        fileName={seo?.image?.name?.original}
                                        fileExtension={seo?.image?.extension?.original}
                                        fileSize={seo?.image?.size?.current}
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                        </PlainContainer>
                    }
                </Card>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Sitemap"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSitemapPopUp(true)} isBottomAction={false}>
                        {
                            sitemap === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No Sitemap Details found!"}
                                        description={"Update sitemap details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateSitemapPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetSitemapRequest("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                {
                                    sitemap?.page?.name != ""
                                    ?
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"page"} 
                                            description={defaults.capitalize(sitemap?.page?.name)} 
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                                {
                                    sitemap?.image != undefined
                                    ?
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Image`}
                                            preview={`${globals.API_BASE_URL}${sitemap?.image?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={sitemap?.image?.name?.original}
                                            fileExtension={sitemap?.image?.extension?.original}
                                            fileSize={sitemap?.image?.size?.current}
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"About Us Section"} headingActionIcon={icons.addSolidCircle} headingAction={() => console.log(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Section Headings"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateAboutSectionHeadingsPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"tagline"} 
                                            description={defaults.capitalize(aboutSection?.section_headings?.tagline)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"heading"} 
                                            description={defaults.capitalize(aboutSection?.section_headings?.heading)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"description"} 
                                            description={defaults.capitalize(aboutSection?.section_headings?.description)} 
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Cover Images"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateAboutCoverImagesPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Portrait Image`}
                                            preview={`${globals.API_BASE_URL}${aboutSection?.cover_images?.portrait?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={aboutSection?.cover_images?.portrait?.name?.original}
                                            fileExtension={aboutSection?.cover_images?.portrait?.extension?.original}
                                            fileSize={aboutSection?.cover_images?.portrait?.size?.current}
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Square Top Image`}
                                            preview={`${globals.API_BASE_URL}${aboutSection?.cover_images?.square_top?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={aboutSection?.cover_images?.square_top?.name?.original}
                                            fileExtension={aboutSection?.cover_images?.square_top?.extension?.original}
                                            fileSize={aboutSection?.cover_images?.square_top?.size?.current}
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Square Bottom Image`}
                                            preview={`${globals.API_BASE_URL}${aboutSection?.cover_images?.square_bottom?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={aboutSection?.cover_images?.square_bottom?.name?.original}
                                            fileExtension={aboutSection?.cover_images?.square_bottom?.extension?.original}
                                            fileSize={aboutSection?.cover_images?.square_bottom?.size?.current}
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>
                    

                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Statistics"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateAboutStatsPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Stats 1 - Lable"} 
                                            description={defaults.capitalize(aboutSection?.statistics?.stat_1?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Stats 1 - Count"} 
                                            description={defaults.capitalize(aboutSection?.statistics?.stat_1?.count)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Stats 2 - Lable"} 
                                            description={defaults.capitalize(aboutSection?.statistics?.stat_2?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Stats 2 - Count"} 
                                            description={defaults.capitalize(aboutSection?.statistics?.stat_2?.count)} 
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Description"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateAboutDescriptionPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <div dangerouslySetInnerHTML={{__html: aboutSection?.description}}></div>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>
                                
                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Highlights"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddAboutHighlightPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <Table>
                                        <TableHeading>
                                            <TableHeadingItem size={4} lable={"lable"} />
                                            <TableHeadingItem size={1} lable={"created by"} />
                                            <TableHeadingItem size={2} lable={"Actions"} />
                                        </TableHeading>

                                        <SortableAboutHighlightContainer onSortEnd={handleAboutHighlightSortEnd} useDragHandle>
                                            {aboutSection?.highlights.map((item, index) => (
                                                <SortableAboutHighlightItem key={`item-${index}`} index={index} value={item} />
                                            ))}
                                        </SortableAboutHighlightContainer>
                                    </Table>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Actions"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateAboutActionPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Primary - Lable"} 
                                            description={defaults.capitalize(aboutSection?.actions?.primary?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Primary - Action"} 
                                            description={defaults.capitalize(aboutSection?.actions?.primary?.action)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Secondary - Lable"} 
                                            description={defaults.capitalize(aboutSection?.actions?.secondary?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Secondary - Value"} 
                                            description={defaults.capitalize(aboutSection?.actions?.secondary?.value)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Secondary Icon`}
                                            preview={`${globals.API_BASE_URL}${aboutSection?.actions?.secondary?.icon?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={aboutSection?.actions?.secondary?.icon?.name?.original}
                                            fileExtension={aboutSection?.actions?.secondary?.icon?.extension?.original}
                                            fileSize={aboutSection?.actions?.secondary?.icon?.size?.current}
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>
                            </Card>
                        </PlainContainer>
                   </PlainContainer>

            
            {
                showUpdateSitemapPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Sitemap" closePopUp={() => handleConcludeUpdateSitemapPopUp({status: false})}>
                    <UpdateAboutUsPageSitemapPopUp user={user} sitemap={sitemap} concludeUpdateSitemapPopUp={(e) => handleConcludeUpdateSitemapPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateAboutSectionHeadingsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section Headings" closePopUp={() => handleConcludeUpdateAboutSectionHeadingPopUp({status: false})}>
                    <UpdateAboutUsPageAboutSectionHeadingsPopUp user={user} section_headings={aboutSection?.section_headings} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateAboutSectionHeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateAboutCoverImagesPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Cover Images" closePopUp={() => handleConcludeUpdateAboutCoverImagesPopUp({status: false})}>
                    <UpdateAboutUsPageAboutCoverImagesPopUp user={user} cover_images={aboutSection?.cover_images} concludeUpdateCoverImagesPopUp={(e) => handleConcludeUpdateAboutCoverImagesPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {

                showUpdateAboutStatsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Stats" closePopUp={() => handleConcludeUpdateAboutStatsPopUp({status: false})}>
                  <UpdateAboutUsPageAboutStatsPopUp user={user} stats={aboutSection?.statistics} concludeUpdateStatsPopUp={(e) => handleConcludeUpdateAboutStatsPopUp(e)} />
                </PopUp>
                :
                <></>

            }

            {
                showUpdateAboutDescriptionPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Description" closePopUp={() => handleConcludeUpdateAboutDescriptionPopUp({status: false})}>
                    <UpdateAboutUsPageDescriptionPopUp user={user} data={aboutSection?.description} concludeUpdateDescriptionPopUp={(e) => handleConcludeUpdateAboutDescriptionPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddAboutHighlightPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Highlight" closePopUp={() => handleConcludeAddAboutHighlightPopUp({status: false})}>
                <AddAboutUsPageAboutHighlightPopUp user={user} concludeAddHighlightPopUp={(e) => handleConcludeAddAboutHighlightPopUp(e)} />
                </PopUp>
                :
                <></>
            }
            {
                showUpdateAboutHighlightPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Highlight" closePopUp={() => handleConcludeUpdateAboutHighlightPopUp({status: false})}>
                    <UpdateAboutUspageAboutHighlightPopUp user={user} highlight={aboutHighlightToUpdate} concludeUpdateHighlightPopUp={(e) => handleConcludeUpdateAboutHighlightPopUp(e)} />
                </PopUp>
                :
                <></>
            }
            {
                showUpdateAboutActionPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Actions" closePopUp={() => handleConcludeUpdateAboutActionPopUp({status: false})} >
                <UpdateAboutPageAboutActionPopUp user={user} action={aboutSection?.actions} concludeUpdateActionPopUp={(e) => handleConcludeUpdateAboutActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }
        </>
    )
}

export default AboutUsPage